import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, from } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { DataStore, syncExpression } from '@aws-amplify/datastore';

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../models';
import { AppService } from '../../../utils/services/app.service';
import { AuthService } from '../../../utils/services/auth.service';


@Component({
  selector: 'app-accountmanagement',
  templateUrl: './accountmanagement.component.html',
  styleUrls: ['./accountmanagement.component.scss']
})
export class AccountManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  accounts;
  loginEmail;
  editTarget;
  tenantId: string = "";
  subscription1;


  constructor(
    private router: Router,
    private renderer: Renderer2,
    private appService: AppService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.list();
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.dtOptions = {
      search: {
        regex: false,
      },
      scrollX: true,
      scrollCollapse: true,
      order: [0, 'asc'],
      stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.list();
        this.auth.getLoginEmail()
          .subscribe(result => {
          this.loginEmail = result;
        });
        this.accounts.subscribe(resp => {
          resp.map((element, index) => {
            let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
            let iscecMaintenanceAccount = element.email.match(regex);
            let isAuthCecMaintenance = this.loginEmail.match(regex);
            // CEC保守管理者の場合、マスクする
            if (iscecMaintenanceAccount) {
              let cecMaintenance = {
                id: resp[index]['id'],
                isSystemAdmin: false,
                isTenantAdmin: true,
                isTenantUser: false,
                email: 'CECメンテナンス',
                groupName: '',
                userCreateDate: '',
                userLastModifiedDate: '',
              }
              resp.splice(index, 1, cecMaintenance)

              // ログインアカウントがCEC保守管理者の場合、チェックする
              if (isAuthCecMaintenance) {
                resp[index]['isAuthCecMaintenance'] = true;
              }
            }
          });
          callback({
            data: resp,
          });
        })
      },
      columns: [
        {
          title: '種別',
          name: 'enabled',
          render: function (data: any, type: any, full: any) {
            if (full.isSystemAdmin) {
              return '<span class="label label-success margin-r-5">システム管理者</span>';
            }
            else if (full.isTenantAdmin) {
              return '<span class="label label-danger margin-r-5">管理者</span>';
            }
            else if (full.isTenantUser) {
              return '<span class="label label-danger margin-r-5">ユーザー</span>';
            }
          }
        },
        {
          title: 'メールアドレス',
          data: 'email',
          name: 'email',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '所属名',
          data: 'groupName',
          name: 'groupName',
          render: $.fn.dataTable.render.text(),
        },
        /*
        {
          title: '状態',
          name: 'enabled',
          render: function (data: any, type: any, full: any) {
            if (full.enabled) {
              return '<span class="label label-success margin-r-5">有効</span>';
            }
            else {
              return '<span class="label label-danger margin-r-5">無効</span>';
            }
          }
        },
        {
          title: '有効期限',
          data: 'expirationEndDate',
          name: 'expirationEndDate',
        },
        */
        {
          title: '作成日時',
          data: 'userCreateDate',
          name: 'userCreateDate',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '更新日時',
          data: 'userLastModifiedDate',
          name: 'userLastModifiedDate',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '操作',
          orderable: false,
          render: function (data: any, type: any, full: any) {
            if (full.email == 'CECメンテナンス') {
              if (full.isAuthCecMaintenance) {
                return '<button type="button" edit-account-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
                        '<button type="button" delete-account-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
              } else {
                return '';
              }
            }
            return '<button type="button" edit-account-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
                    '<button type="button" delete-account-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
          }
        }
      ]
    };
  }

  list() {
    this.accounts = from(DataStore.query(AccountMaster, c => c.tenantId("eq", this.tenantId)));
  }

  getloginEmail() {
    this.loginEmail = this.auth.getLoginEmail()
  }

  private get(id: string) {
    return from(DataStore.query(AccountMaster, id));
  }

  onClickCreateAccount() {
    this.router.navigate(['/accountmanagement/create'])
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.list();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      this.accounts.subscribe(resp => {
        if (event.target.hasAttribute("edit-account-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("edit-account-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/accountmanagement/edit'], { state: { target: item } });
              });
            }
          });
        }
        else if (event.target.hasAttribute("delete-account-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("delete-account-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/accountmanagement/delete'], { state: { target: item } });
              });
            }
          });
        }

      })
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.dtTrigger.unsubscribe();
  }
}
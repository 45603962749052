<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>対象者管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">対象者削除</h3></div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">氏名</label>
            <input type="text" formControlName="name" class="form-control" placeholder="Name" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">uid</label>
            <input type="text" formControlName="uid" class="form-control" placeholder="uid" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">説明</label>
            <input type="text" formControlName="description" class="form-control" placeholder="Description" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">管理番号</label>
            <input type="text" formControlName="roomNumber" class="form-control" placeholder="Room Number" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効／無効</label>
            <input type="checkbox" formControlName="enable" class="form-control" placeholder="enable" id="Enable" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像１<small class="text-muted">（jpeg）</small></label>
            <img [src]="img1Src" alt="">
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像２<small class="text-muted">（jpeg）</small></label>
            <img [src]="img2Src" alt="">
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像３<small class="text-muted">（jpeg）</small></label>
            <img [src]="img3Src" alt="">
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像４<small class="text-muted">（jpeg）</small></label>
            <img [src]="img4Src" alt="">
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像５<small class="text-muted">（jpeg）</small></label>
            <img [src]="img5Src" alt="">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート設定<br></label>
            <select formControlName="alertSettingMasterId" class="form-control" #alertSettingMasterId>
              <option *ngFor="let alertSetting of alertSettings" [value]="alertSetting.id">
                {{ alertSetting.name }}
              </option>
            </select>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickDelete()" type="submit" class="btn btn-primary">削除</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

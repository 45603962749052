import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Observable, Subscription } from 'rxjs';

import { DataStore } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { Tenant } from '../../../../../models';

@Component({
  selector: 'app-deletetenant',
  templateUrl: './deletetenant.component.html',
  styleUrls: ['./deletetenant.component.scss']
})
export class DeleteTenantComponent implements OnInit {
  public deleteForm: FormGroup;
  isValidFormSubmitted = null;
  deleteTarget;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.deleteTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }
   }

  ngOnInit() {
    this.deleteForm = this.formBuilder.group({
      name: [{value: this.deleteTarget.name, disabled: true}, [Validators.required, Validators.maxLength(64)]],
      license: [{value: this.deleteTarget.licenseCode, disabled: true}, [Validators.required, Validators.maxLength(40)]],
      domainList: [{value: this.deleteTarget.domainList, disabled: true}, [Validators.maxLength(512)]],
      expirationStartDate: [{value: this.deleteTarget.expirationStartDate, disabled: true}, [Validators.required]],
      expirationEndDate: [{value: this.deleteTarget.expirationEndDate, disabled: true}, [Validators.required]],
      mailaddressOfAlert: [{value: this.deleteTarget.mailaddressOfAlert, disabled: true}, [Validators.required, Validators.maxLength(256)]],
      monitoringInterval: [{value: this.deleteTarget.monitoringInterval, disabled: true}, [Validators.required, Validators.min(0), Validators.max(100)]],
      continuousMoveThreshold: [{value: this.deleteTarget.continuousMoveThreshold, disabled: true}, [Validators.required, Validators.min(1), Validators.max(100)]],
      sendAlertInterval: [{value: this.deleteTarget.sendAlertInterval, disabled: true}, [Validators.required, Validators.min(0), Validators.max(100)]],
      alertMessageRetentionPeriod: [{value: this.deleteTarget.alertMessageRetentionPeriod, disabled: true}, [Validators.required, Validators.min(1), Validators.max(365)]],
      licenseOfEscape: [{value: this.deleteTarget.licenseOfEscape, disabled: true}],
      licenseOfHighFever: [{value: this.deleteTarget.licenseOfHighFever, disabled: true}],
      licenseOfUnKnown: [{value: this.deleteTarget.licenseOfUnKnown, disabled: true}],
    });
  }

  private get(id: string) {
    return from(DataStore.query(Tenant, id));
  }

  private delete(id: string) {
    this.get(id).subscribe(item => DataStore.delete(item).then(result => {
      this.toastr.success('削除されました。', '成功');
      this.router.navigate(['/tenantmanagement'])
    }).catch(error => {
      this.toastr.error(error.message, 'エラー');
    }));
  }

  onClickDeleteTenant() {
    this.isValidFormSubmitted = true;
    this.spinner.show();
    if (!this.deleteTarget.isSystemAdmin) {
      this.delete(this.deleteTarget.id);
    }
    setTimeout(() => this.spinner.hide(), 500);
  }


  ngAfterViewInit() {
  }

}

<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>アラート履歴（全体）</h1>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">

        <h3 class="card-title">アラート履歴リスト
          <button type="button" (click)="rerender()" class="btn btn-default btn-sm">
            <i class="fas fa-sync-alt"></i>
          </button>
        </h3>

        <div>
          <div class="card-title">
            <div class="row">
              <button id="period1d" (click)="list1Day(); bun1Day()" value="1day" type="button" class="btn btn-secondary">1日</button>
            </div>
          </div>
          <div class="card-title">
            <div class="row">
              <button id="periodfull" (click)="listFull(); bunFull()" value="full" type="button" class="btn btn-secondary">FULL</button>
            </div>
          </div>
          <!-- <div class="card-title">
            <div class="row">
              <button id="period3d" (click)="list3Days(); bun3Days()" value="3days" type="button" class="btn btn-secondary">3日</button>
            </div>
          </div>
          <div class="card-title">
            <div class="row">
              <button id="period1w" (click)="list1Week(); bun1Week()" value="1week" type="button" class="btn btn-secondary">1週</button>
            </div>
          </div>
          <div class="card-title">
            <div class="row">
              <button id="period1m" (click)="list1Month(); bun1Month()" value="1month" type="button" class="btn btn-secondary">1ヶ月</button>
            </div>
          </div>
          <div class="card-title">
            <div class="row">
              <button id="period3m" (click)="list3Months(); bun3Months()" value="3months" type="button" class="btn btn-secondary">3ヶ月</button>
            </div>
          </div>
          <div class="card-title">
            <div class="row">
              <button id="period6m" (click)="list6Months(); bun6Months()" value="6months" type="button" class="btn btn-secondary">6ヶ月</button>
            </div>
          </div> -->
        </div>

      </div>

      <div class="card-body">
        <div class="table-responsive">

          <div class="form-group row">
            <div class="col-sm-3">
              <label for="sel1">From: </label>
              <input type="date" class="dateFrom clearValue form-control" #fromDate (ngModel)="fromDate" min="2000-01-01" max="2100-12-31">
            </div>
            <div class="col-sm-3">
              <label for="sel2">To: </label>
              <input type="date" class="dateTo clearValue form-control" #toDate (ngModel)="toDate" min="2000-01-01" max="2100-12-31">
            </div>
            <div class="card-title-2" style="margin-bottom: 15px;">
              <div class="row">
                <button type="button"
                        class="btn btn-secondary"
                        (click)="filterView()">select</button>
              </div>
            </div>
            <div class="card-title-2">
              <div class="row">
                <button (click)="clear()" type="button" class="btn btn-secondary" [disabled]="btnDisabled">clear</button>
              </div>
            </div>
          </div>

          <table summary="Account List" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-striped table-bordered dt-responsive nowrap hover" style="width:100%">
            <tfoot style="display: table-header-group;">
              <tr>
                <th><input type="text" placeholder="Search 種別" formControlName="searchAlertKind" /></th>
                <th><input type="text" placeholder="Search 氏名" formControlName="searchName"/></th>
                <th><input type="text" placeholder="Search uid" formControlName="searchUid"/></th>
                <th><input type="text" placeholder="Search インフォメーション" formControlName="searchContent"/></th>
                <th><input type="text" placeholder="Search 詳細" formControlName="searchDetail"/></th>
                <th><input type="text" placeholder="Search カメラ情報" formControlName="searchCameraDescription"/></th>
                <th><input type="text" placeholder="Search 発生日時" formControlName="searchShootingDataTime"/></th>
                <th></th>
                <th></th>
                <th></th>
                <!--
                <th></th>
                -->
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';

import { DataStore, DISCARD, syncExpression } from '@aws-amplify/datastore';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../models';
import { AppService } from '../../../utils/services/app.service';

@Component({
  selector: 'app-tenantsetting',
  templateUrl: './tenantsetting.component.html',
  styleUrls: ['./tenantsetting.component.scss']
})
export class TenantSettingComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  editTarget;
  tenantId: string = "";
  subscription1;

  constructor(
    private formBuilder: FormBuilder,
    public appService: AppService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.gettenant();
      });

    this.registerForm = this.formBuilder.group({
      continuousMoveThreshold: [0, [Validators.required, Validators.min(1), Validators.max(600), Validators.pattern('[^",]+')]],
      sendAlertInterval: [0, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[^",]+')]],
    });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });
  }

  gettenant() {
    let alertSettingssub = from(DataStore.query(Tenant, c => c.id("eq", this.tenantId)));
    alertSettingssub.subscribe(resp => {
      this.editTarget = resp[0];
      this.registerForm.patchValue(this.editTarget);
    })
  }

  private get(id: string) {
    return from(DataStore.query(Tenant, id));
  }

  onClickEditTargetTenant() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      let continuousMoveThreshold = this.registerForm.get("continuousMoveThreshold").value;
      let sendAlertInterval = this.registerForm.get("sendAlertInterval").value;
      this.get(this.editTarget.id).subscribe(item => {
        DataStore.save(
          Tenant.copyOf(item, updated => {
            updated.continuousMoveThreshold = continuousMoveThreshold;
            updated.sendAlertInterval = sendAlertInterval;
          })
        ).then( result => {
          this.toastr.success('保存されました。', '成功');
        }).catch(error => {
          this.toastr.error(error.message, 'エラー');
        });
      });
      setTimeout(() => this.spinner.hide(), 500);
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { API } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DataStore, syncExpression } from '@aws-amplify/datastore';

import { AppService } from '../../../../utils/services/app.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-editaccount',
  templateUrl: './editaccount.component.html',
  styleUrls: ['./editaccount.component.scss']
})
export class EditAccountComponent implements OnInit {
  public updateForm: FormGroup;
  isValidFormSubmitted = null;
  editTarget;
  tenantId: string = "";
  subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appService: AppService,
  ) {
    try {
      this.editTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }
  }

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      email: [{value: this.editTarget.email, disabled: true}, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
      groupName: [this.editTarget.groupName, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
      //expirationEndDate: [this.editTarget.expirationEndDate, [Validators.required]],
      // isTenantAdmin: [this.editTarget.isTenantAdmin],
      isTenantAdmin: [{value: true, disabled: true}],
    });

    this.subscription = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });
  }

  onClickEditAccount() {
    this.isValidFormSubmitted = true;
    if (this.updateForm.valid) {
      this.spinner.show();
      let userName = this.editTarget.userName;
      let groupName = this.updateForm.get("groupName").value;
      //let expirationEndDate = this.updateForm.get("expirationEndDate").value;
      let expirationEndDate = '2030/12/31';
      let isTenantAdmin = true;
      // let isTenantAdmin = false;
      // if (this.updateForm.get("isTenantAdmin").value == true) {
      //   isTenantAdmin = true;
      // }
      const myInit = {
        body: {
          userName: userName,
          groupName: groupName,
          expirationEndDate: expirationEndDate,
          isTenantAdmin: isTenantAdmin,
        },
        headers: {}
      };
      API
        .post('hitoterracerestapi', '/accounts', myInit)
        .then(response => {
          if (response.body.code == 100) {
            this.toastr.error('すでに同じメールアドレスが登録されています。', 'エラー');
          } else if (response.body.code == 101) {
            this.toastr.error('該当のユーザーが存在しません。', 'エラー');
          } else {
            this.toastr.success('保存されました。', '成功');
            this.toastr.success('しばらく待ってからリストを更新してください。', '成功');
            this.updateForm.reset();
            this.router.navigate(['/accountmanagement'])
          }
          setTimeout(() => this.spinner.hide(), 500);
        })
        .catch(error => {
          this.toastr.error(error.response, 'エラー');
          setTimeout(() => this.spinner.hide(), 500);
        });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }

  }

  get f() { return this.updateForm.controls; }

  ngAfterViewInit() {
  }
}

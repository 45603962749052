import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { AuthService } from 'src/app/utils/services/auth.service';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { Tenant, AlertMessage, AlertMessagePrivate, AlertMessageStatus,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

import { Hub } from 'aws-amplify';   
         

@Component({
  selector: 'app-tenantadmindashboard',
  templateUrl: './tenantadmindashboard.component.html',
  styleUrls: ['./tenantadmindashboard.component.scss']
})
export class TenantAdminDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  subscription: Subscription;
  tenantId: string = '';
//tenantName: string = '';
  ready: boolean = false;

  ngOnInit() {
    this.observe();
    this.subscription = this.auth.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    DataStore.start();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async observe() {
    Hub.listen("datastore", (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      // console.log("DataStore event", event, data);
      if (event === "syncQueriesStarted"){
        // console.log("DataStoreの同期を開始しました");
        this.spinner.show("sync-spinner");
      }

      if (event === "ready"){
        // console.log("DataStoreの同期が完了しました");
        this.spinner.hide("sync-spinner");
      }

    });
  }
}

<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>アラート設定</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">アラート設定作成</h3></div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">アラート名<small class="text-muted">&nbsp;&nbsp;256文字</small></label>
            <input type="text" formControlName="name" class="form-control" placeholder="Name"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.name.errors }" />
            <div *ngIf="isValidFormSubmitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
              <div *ngIf="f.name.errors.maxlength">max 256 characters</div>
              <div *ngIf="f.name.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">説明<small class="text-muted">&nbsp;&nbsp;300文字</small></label>
            <textarea rows="3" formControlName="description" class="form-control" placeholder="Description"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.description.errors }"></textarea>
            <div *ngIf="isValidFormSubmitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Description is required</div>
              <div *ngIf="f.description.errors.maxlength">max 300 characters</div>
              <div *ngIf="f.description.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">通知対象１のアカウントメールアドレス</label>
            <select formControlName="notification1UserName"class="form-control" #notification1UserName [ngClass]="{ 'is-invalid': mailDuplicated }">
              <!-- <ng-container *ngFor="let account of accounts">
                <option *ngIf="isCECMaintenanceAccount()" [value]="account.userName">{{ account.email }}</option>
              </ng-container> -->
              <option *ngFor="let account of accounts" [value]="account.userName">
                {{ account.email }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">通知対象２のアカウントメールアドレス</label>
            <select formControlName="notification2UserName" class="form-control" #notification2UserName [ngClass]="{ 'is-invalid': mailDuplicated }">
              <option *ngFor="let account of accounts" [value]="account.userName">
                {{ account.email }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">通知対象３のアカウントメールアドレス</label>
            <select formControlName="notification3UserName" class="form-control" #notification3UserName [ngClass]="{ 'is-invalid': mailDuplicated }">
              <option *ngFor="let account of accounts" [value]="account.userName">
                {{ account.email }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">通知対象４のアカウントメールアドレス</label>
            <select formControlName="notification4UserName" class="form-control" #notification4UserName [ngClass]="{ 'is-invalid': mailDuplicated }">
              <option *ngFor="let account of accounts" [value]="account.userName">
                {{ account.email }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">通知対象５のアカウントメールアドレス</label>
            <select formControlName="notification5UserName" class="form-control" #notification5UserName [ngClass]="{ 'is-invalid': mailDuplicated }">
              <option *ngFor="let account of accounts" [value]="account.userName">
                {{ account.email }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">アラートの種類（離院）</label>
            <input type="checkbox" formControlName="isAlertEscape" class="form-control" placeholder="Alert Of Escape" id="isAlertEscape" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">アラートの種類（検温）</label>
            <input type="checkbox" formControlName="isAlertHighFever" class="form-control" placeholder="Alert Of HighFever" id="isAlertHighFever" />
          </div>
<!--
          <div class="input-group mb-3">
            <label class="col-md-4">離院判定種別<small class="text-muted">&nbsp;&nbsp;入、出、両方</small></label>
            <select formControlName="escapeType" class="form-control" #escapeType>
              <option *ngFor="let escapeType of escapeTypes" [value]="escapeType.value">
                {{ escapeType.name }}
              </option>
            </select>
          </div>
-->
          <div class="input-group mb-3">
            <label class="col-md-4">未登録者を対象にする</label>
            <input type="checkbox" formControlName="isUnknownTarget" class="form-control" placeholder="Target Of Unknown" id="isUnknownTarget" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">HIKVISION専用</label>
            <input type="checkbox" formControlName="isHikvision" class="form-control" placeholder="HIKVISION専用" id="isHikvision" />
          </div>

<!--
          <div class="input-group mb-3">
            <label class="col-md-4">検温の温度閾値</label>
            <input type="number" step="0.1" formControlName="highFeverThreshold" class="form-control" placeholder="HighFever Threshold" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">月曜日のアラート送信時間</label>
            <input type="time" formControlName="mondayStartTime" class="form-control col-md-3" placeholder="Monday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="mondayEndTime" class="form-control col-md-3" placeholder="Monday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">火曜日のアラート送信時間</label>
            <input type="time" formControlName="tuesdayStartTime" class="form-control col-md-3" placeholder="Tuesday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="tuesdayEndTime" class="form-control col-md-3" placeholder="Tuesday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">水曜日のアラート送信時間</label>
            <input type="time" formControlName="wednesdayStartTime" class="form-control col-md-3" placeholder="Wednesday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="wednesdayEndTime" class="form-control col-md-3" placeholder="Wednesday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">木曜日のアラート送信時間</label>
            <input type="time" formControlName="thursdayStartTime" class="form-control col-md-3" placeholder="Thursday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="thursdayEndTime" class="form-control col-md-3" placeholder="Thursday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">金曜日のアラート送信時間</label>
            <input type="time" formControlName="fridayStartTime" class="form-control col-md-3" placeholder="Friday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="fridayEndTime" class="form-control col-md-3" placeholder="Friday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">土曜日のアラート送信時間</label>
            <input type="time" formControlName="saturdayStartTime" class="form-control col-md-3" placeholder="Saturday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="saturdayEndTime" class="form-control col-md-3" placeholder="Saturday End Time" />
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">日曜日のアラート送信時間</label>
            <input type="time" formControlName="sundayStartTime" class="form-control col-md-3" placeholder="Sunday Start Time" />
            <div class="col-md-1" style="text-align: center;">
              -
            </div>
            <input type="time" formControlName="sundayEndTime" class="form-control col-md-3" placeholder="Sunday End Time" />
          </div>
-->
        </div>
        <div class="card-footer">
          <button (click)="onClickCreateAlertSetting()" type="submit" class="btn btn-primary">作成</button>
        </div>
      </form>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AppService } from '../../../../utils/services/app.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-deletealertsetting',
  templateUrl: './deletealertsetting.component.html',
  styleUrls: ['./deletealertsetting.component.scss']
})
export class DeleteAlertSettingComponent implements OnInit {
  public deleteForm: FormGroup;
  isValidFormSubmitted = null;
  deleteTarget;
  tenantId: string = "";
  subscription1;
  subscription2;
  accounts;
  escapeTypes = [
    {name: 'in', value: 'in'},
    {name: 'out', value: 'out'},
    {name: 'both', value: 'both'},
  ]

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.deleteTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }
   }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.listAccountMaster();
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.subscription2 = DataStore.observe(AccountMaster).subscribe(msg => {
      this.listAccountMaster();
    });
    this.deleteForm = this.formBuilder.group({
      name: [{value: this.deleteTarget.name, disabled: true}, [Validators.required, Validators.maxLength(256)]],
      description: [{value: this.deleteTarget.description, disabled: true}, [Validators.required, Validators.maxLength(300)]],
      notification1UserName: [{value: this.deleteTarget.notification1UserName, disabled: true}],
      notification2UserName: [{value: this.deleteTarget.notification2UserName, disabled: true}],
      notification3UserName: [{value: this.deleteTarget.notification3UserName, disabled: true}],
      notification4UserName: [{value: this.deleteTarget.notification4UserName, disabled: true}],
      notification5UserName: [{value: this.deleteTarget.notification5UserName, disabled: true}],
      isAlertEscape: [{value: this.deleteTarget.isAlertEscape, disabled: true}],
      isAlertHighFever: [{value: this.deleteTarget.isAlertHighFever, disabled: true}],
      isAlertUnKnown: [{value: this.deleteTarget.isAlertUnKnown, disabled: true}],
      escapeType: [{value: this.deleteTarget.escapeType, disabled: true}],
      highFeverThreshold: [{value: this.deleteTarget.highFeverThreshold, disabled: true}],
      mondayStartTime: [{value: this.deleteTarget.mondayStartTime, disabled: true}],
      mondayEndTime: [{value: this.deleteTarget.mondayEndTime, disabled: true}],
      tuesdayStartTime: [{value: this.deleteTarget.tuesdayStartTime, disabled: true}],
      tuesdayEndTime: [{value: this.deleteTarget.tuesdayEndTime, disabled: true}],
      wednesdayStartTime: [{value: this.deleteTarget.wednesdayStartTime, disabled: true}],
      wednesdayEndTime: [{value: this.deleteTarget.wednesdayEndTime, disabled: true}],
      thursdayStartTime: [{value: this.deleteTarget.thursdayStartTime, disabled: true}],
      thursdayEndTime: [{value: this.deleteTarget.thursdayEndTime, disabled: true}],
      fridayStartTime: [{value: this.deleteTarget.fridayStartTime, disabled: true}],
      fridayEndTime: [{value: this.deleteTarget.fridayEndTime, disabled: true}],
      saturdayStartTime: [{value: this.deleteTarget.saturdayStartTime, disabled: true}],
      saturdayEndTime: [{value: this.deleteTarget.saturdayEndTime, disabled: true}],
      sundayStartTime: [{value: this.deleteTarget.sundayStartTime, disabled: true}],
      sundayEndTime: [{value: this.deleteTarget.sundayEndTime, disabled: true}],
      isUnknownTarget: [{value: this.deleteTarget.isUnknownTarget, disabled: true}],
      isHikvision: [{value: this.deleteTarget.isHikvision, disabled: true}],
    });
  }

  listAccountMaster() {
    let accountssub = from(DataStore.query(AccountMaster, c => c.tenantId("eq", this.tenantId)));
    accountssub.subscribe(resp => {
      this.accounts = [{ userName: '', email: '選択なし' }];
      this.accounts = this.accounts.concat(resp);
    })
  }

  private get(id: string) {
    return from(DataStore.query(AlertSettingMaster, id));
  }

  private delete(id: string) {
    this.get(id).subscribe(item => DataStore.delete(item).then(result => {
      this.toastr.success('削除されました。', '成功');
      this.router.navigate(['/alertsettingmanagement'])
    }).catch(error => {
      this.toastr.error(error.message, 'エラー');
    }));
  }

  onClickDeleteAlertSetting() {
    this.isValidFormSubmitted = true;
    this.spinner.show();
    this.delete(this.deleteTarget.id);
    setTimeout(() => this.spinner.hide(), 500);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    // this.subscription1.unsubscribe();
    // this.subscription2.unsubscribe();
  }
}

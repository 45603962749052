<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>テナント管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">テナント削除</h3></div>

      <form [formGroup]="deleteForm">
        <div class="card-body">
          <div class="input-group mb-3">
            <label class="col-md-4">テナント名<small class="text-muted">&nbsp;&nbsp;64文字</small></label>
            <input type="text" formControlName="name" class="form-control col-md-8" placeholder="Name">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">ライセンスコード<small class="text-muted">&nbsp;&nbsp;40文字以内</small></label>
            <input type="text" formControlName="license" class="form-control col-md-8" placeholder="License Code">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">登録可能なメールのドメインリスト<small class="text-muted">&nbsp;&nbsp;カンマ区切り、512文字以内</small></label>
            <input type="text" formControlName="domainList" class="form-control col-md-8" placeholder="Domain List">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効期限開始<small class="text-muted">（例：2020-09-01）</small></label>
            <input type="date" formControlName="expirationStartDate" class="form-control col-md-8" placeholder="Start Date">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効期限終了<small class="text-muted">（例：2020-09-30）</small></label>
            <input type="date" formControlName="expirationEndDate" class="form-control col-md-8" placeholder="End Date">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">システムアラートメールの送信先アドレス<small class="text-muted">&nbsp;&nbsp;256文字以内</small></label>
            <input type="email" formControlName="mailaddressOfAlert" class="form-control col-md-8" placeholder="Alert MailAddress">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">死活監視間隔<small class="text-muted">（分）0：無効</small></label>
            <input type="number" formControlName="monitoringInterval" class="form-control col-md-8" placeholder="Monitor Interval">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">連続移動の閾値<small class="text-muted">（秒）</small></label>
            <input type="number" formControlName="continuousMoveThreshold" class="form-control col-md-8" placeholder="Continuous Move Threshold">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート送信間隔<small class="text-muted">（分）0：無効</small></label>
            <input type="number" formControlName="sendAlertInterval" class="form-control col-md-8" placeholder="Send Alert Interval">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート通知保存期間<small class="text-muted">（日）1～365</small></label>
            <input type="number" formControlName="alertMessageRetentionPeriod" class="form-control col-md-8" placeholder="Alert Message Retention Period">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">離院看視ライセンス</label>
            <input type="checkbox" formControlName="licenseOfEscape" class="form-control col-md-8" placeholder="License Of Escape" id="licenseOfEscape">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">検温検知ライセンス</label>
            <input type="checkbox" formControlName="licenseOfHighFever" class="form-control col-md-8" placeholder="License Of High Fever" id="licenseOfHighFever">
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">不審者監視ライセンス</label>
            <input type="checkbox" formControlName="licenseOfUnKnown" class="form-control col-md-8" placeholder="License Of UnKnown" id="licenseOfUnKnown">
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickDeleteTenant()" type="submit" class="btn btn-primary">削除</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>テナント管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">テナント作成</h3></div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">テナント名<small class="text-muted">&nbsp;&nbsp;64文字</small></label>
            <input type="text" formControlName="name" class="form-control col-md-8" placeholder="Name"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.name.errors }" />
            <div *ngIf="isValidFormSubmitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
              <div *ngIf="f.name.errors.maxLength">Name is max length 64 String</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">ライセンスコード<small class="text-muted">&nbsp;&nbsp;40文字以内</small></label>
            <input type="text" formControlName="license" class="form-control col-md-8" placeholder="License Code"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.license.errors }" />
            <div *ngIf="isValidFormSubmitted && f.license.errors" class="invalid-feedback">
              <div *ngIf="f.license.errors.required">license is required</div>
              <div *ngIf="f.license.errors.maxLength">license is max length 40 String</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">登録可能なメールのドメインリスト<small class="text-muted">&nbsp;&nbsp;カンマ区切り、512文字以内</small></label>
            <input type="text" formControlName="domainList" class="form-control col-md-8" placeholder="Domain List"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.domainList.errors }" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効期限開始<small class="text-muted">（例：2020-09-01）</small></label>
            <input type="date" formControlName="expirationStartDate" class="form-control col-md-8" placeholder="Start Date"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.expirationStartDate.errors }" min="2000-01-01" max="2100-12-31"/>
            <div *ngIf="isValidFormSubmitted && f.expirationStartDate.errors" class="invalid-feedback">
              <div *ngIf="f.expirationStartDate.errors.required">Strat Date is required</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効期限終了<small class="text-muted">（例：2020-09-30）</small></label>
            <input type="date" formControlName="expirationEndDate" class="form-control col-md-8" placeholder="End Date"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.expirationEndDate.errors }" min="2000-01-01" max="2100-12-31"/>
            <div *ngIf="isValidFormSubmitted && f.expirationEndDate.errors" class="invalid-feedback">
              <div *ngIf="f.expirationEndDate.errors.required">End Date is required</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">システムアラートメールの送信先アドレス<small class="text-muted">&nbsp;&nbsp;256文字以内</small></label>
            <input type="email" formControlName="mailaddressOfAlert" class="form-control col-md-8" placeholder="Alert MailAddress"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.mailaddressOfAlert.errors }" />
            <div *ngIf="isValidFormSubmitted && f.mailaddressOfAlert.errors" class="invalid-feedback">
              <div *ngIf="f.mailaddressOfAlert.errors.required">Alert MailAddress is required</div>
              <div *ngIf="f.mailaddressOfAlert.errors.maxLength">Alert MailAddress is max length 256 String</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">死活監視間隔<small class="text-muted">（分）0：無効</small></label>
            <input type="number" formControlName="monitoringInterval" class="form-control col-md-8" placeholder="Monitor Interval"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.monitoringInterval.errors }" />
            <div *ngIf="isValidFormSubmitted && f.monitoringInterval.errors" class="invalid-feedback">
              <div *ngIf="f.monitoringInterval.errors.required">Monitor Interval is required</div>
              <div *ngIf="f.monitoringInterval.errors.min">Monitor Interval is 0 ~ 100</div>
              <div *ngIf="f.monitoringInterval.errors.max">Monitor Interval is 0 ~ 100</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">連続移動の閾値<small class="text-muted">（秒）</small></label>
            <input type="number" formControlName="continuousMoveThreshold" class="form-control col-md-8" placeholder="Continuous Move Threshold"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.continuousMoveThreshold.errors }" />
            <div *ngIf="isValidFormSubmitted && f.continuousMoveThreshold.errors" class="invalid-feedback">
              <div *ngIf="f.continuousMoveThreshold.errors.required">Continuous Move Threshold is required</div>
              <div *ngIf="f.continuousMoveThreshold.errors.min">Monitor Interval is 1 ~ 600</div>
              <div *ngIf="f.continuousMoveThreshold.errors.max">Monitor Interval is 1 ~ 600</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート送信間隔<small class="text-muted">（分）0：無効</small></label>
            <input type="number" formControlName="sendAlertInterval" class="form-control col-md-8" placeholder="Send Alert Interval"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.sendAlertInterval.errors }" />
            <div *ngIf="isValidFormSubmitted && f.sendAlertInterval.errors" class="invalid-feedback">
              <div *ngIf="f.sendAlertInterval.errors.required">Send Alert Interval is required</div>
              <div *ngIf="f.sendAlertInterval.errors.min">Monitor Interval is 0 ~ 100</div>
              <div *ngIf="f.sendAlertInterval.errors.max">Monitor Interval is 0 ~ 100</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート通知保存期間<small class="text-muted">（日）1～365</small></label>
            <input type="number" formControlName="alertMessageRetentionPeriod" class="form-control col-md-8" placeholder="Alert Message Retention Period"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.alertMessageRetentionPeriod.errors }" />
            <div *ngIf="isValidFormSubmitted && f.alertMessageRetentionPeriod.errors" class="invalid-feedback">
              <div *ngIf="f.alertMessageRetentionPeriod.errors.required">Alert Message Retention Period is required
              </div>
              <div *ngIf="f.alertMessageRetentionPeriod.errors.min">Alert Message Retention Period is 1 ~ 365</div>
              <div *ngIf="f.alertMessageRetentionPeriod.errors.max">Alert Message Retention Period is 1 ~ 365</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">離院看視ライセンス</label>
            <input type="checkbox" formControlName="licenseOfEscape" class="form-control col-md-8" placeholder="License Of Escape" id="licenseOfEscape"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.licenseOfEscape.errors }" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">検温検知ライセンス</label>
            <input type="checkbox" formControlName="licenseOfHighFever" class="form-control col-md-8" placeholder="License Of High Fever" id="licenseOfHighFever"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.licenseOfHighFever.errors }" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">不審者監視ライセンス</label>
            <input type="checkbox" formControlName="licenseOfUnKnown" class="form-control col-md-8" placeholder="License Of UnKnown" id="licenseOfUnKnown"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.licenseOfUnKnown.errors }" />
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickCreateTenant()" type="submit" class="btn btn-primary">作成</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
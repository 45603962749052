import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { AmplifyService } from 'aws-amplify-angular';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AppService } from '../../../../utils/services/app.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';


@Component({
  selector: 'app-deleteargetperson',
  templateUrl: './deletetargetperson.component.html',
  styleUrls: ['./deletetargetperson.component.scss']
})
export class DeleteTargetPersonComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  deleteTarget;
  image1FileToUpload: File = null;
  image2FileToUpload: File = null;
  image3FileToUpload: File = null;
  image4FileToUpload: File = null;
  image5FileToUpload: File = null;
  img1Src: string | ArrayBuffer = "";
  img2Src: string | ArrayBuffer = "";
  img3Src: string | ArrayBuffer = "";
  img4Src: string | ArrayBuffer = "";
  img5Src: string | ArrayBuffer = "";
  prefix = "targetperson/";
  tenantId: string = "";
  subscription1;
  subscription2;
  alertSettings;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private amplifyService: AmplifyService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.deleteTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }

  }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.listAlertSettingMaster();
      });

　　DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.subscription2 = DataStore.observe(AlertSettingMaster).subscribe(msg => {
      this.listAlertSettingMaster();
    });

    this.registerForm = this.formBuilder.group({
      name: [{value: this.deleteTarget.name, disabled: true}, [Validators.required, Validators.maxLength(64)]],
      uid: [{value: this.deleteTarget.uid, disabled: true}, [Validators.required, Validators.maxLength(40), Validators.pattern('[a-zA-Z0-9!-~]*')]],
      description: [{value: this.deleteTarget.description, disabled: true}, [Validators.required, Validators.maxLength(300)]],
      roomNumber: [{value: this.deleteTarget.roomNumber, disabled: true}, [Validators.required, Validators.maxLength(8)]],
      enable: [{value: this.deleteTarget.enable, disabled: true}],
      alertSettingMasterId: [{value: this.deleteTarget.alertSettingMaster.id, disabled: true}, [Validators.required]],
    });

    // マスターの写真
    const targetPersonssub = from(DataStore.query(TargetPersonMaster, c => c.tenantId("eq", this.tenantId)));
    targetPersonssub.subscribe(async targetPersonssub => {
      if (this.deleteTarget.thumbnail1Key != '' && this.deleteTarget.thumbnail1Key !== null) {
        this.img1Src = await this.amplifyService.storage().get(this.deleteTarget.thumbnail1Key.slice(7), { level: 'public' });
      } else {
        this.img1Src = '';
      }

      if (this.deleteTarget.thumbnail2Key != '' && this.deleteTarget.thumbnail2Key !== null) {
        this.img2Src = await this.amplifyService.storage().get(this.deleteTarget.thumbnail2Key.slice(7), { level: 'public' });
      } else {
        this.img2Src = '';
      }

      if (this.deleteTarget.thumbnail3Key != '' && this.deleteTarget.thumbnail3Key !== null) {
        this.img3Src = await this.amplifyService.storage().get(this.deleteTarget.thumbnail3Key.slice(7), { level: 'public' });
      } else {
        this.img3Src = '';
      }

      if (this.deleteTarget.thumbnail4Key != '' && this.deleteTarget.thumbnail4Key !== null) {
        this.img4Src = await this.amplifyService.storage().get(this.deleteTarget.thumbnail4Key.slice(7), { level: 'public' });
      } else {
        this.img4Src = '';
      }

      if (this.deleteTarget.thumbnail5Key != '' && this.deleteTarget.thumbnail5Key !== null) {
        this.img5Src = await this.amplifyService.storage().get(this.deleteTarget.thumbnail5Key.slice(7), { level: 'public' });
      } else {
        this.img5Src = '';
      }
    });
  }

  listAlertSettingMaster() {
    let alertSettingssub = from(DataStore.query(AlertSettingMaster, c => c.tenantId("eq", this.tenantId)));
    alertSettingssub.subscribe(resp => {
      this.alertSettings = resp;
    })
  }

  private get(id: string) {
    return from(DataStore.query(TargetPersonMaster, id));
  }

  private delete(id: string) {
    this.get(id).subscribe(item => DataStore.delete(item).then(result => {
      this.toastr.success('削除されました。', '成功');
      this.router.navigate(['/targetpersonmanagement'])
    }).catch(error => {
      this.toastr.error(error.message, 'エラー');
    }));
  }

  onClickDelete() {
    this.isValidFormSubmitted = true;
    this.spinner.show();
    if(!this.deleteTarget.isSystemAdmin){
      this.delete(this.deleteTarget.id);
    }
    setTimeout(() => this.spinner.hide(), 500);
  }


  ngAfterViewInit() {
  }

  ngOnDestroy() {
    // this.subscription1.unsubscribe();
    // this.subscription2.unsubscribe();
  }
}
